import * as React from 'react';
import selectors from '../../store/selectors';

interface Props {
    merchantDisplayName: string;
    paymentSourceLabel: string;
    amount: number;
}

export default function PaymentAuthorizationAggreement(props: Props) {
    const { amount, merchantDisplayName, paymentSourceLabel } = props;
    const amountStr = selectors.number.amountToString(amount);

    return (
        <div className="text-center mt-3">
            <small>
                By clicking Pay above, you agree to the{' '}
                <a href="https://stronghold.co/legal">Stronghold Pay End User Agreement</a> and you authorize{' '}
                <strong>{merchantDisplayName}</strong> to electronically debit your{' '}
                <strong>{paymentSourceLabel}</strong> for <strong>{amountStr}</strong>.
            </small>
        </div>
    );
}
